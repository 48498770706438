.headerWrapper{
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}
.headerImg{
    height: 40px;
    width: 40px;
    /* margin-left: 5px; */
}
.headerTitle{
    margin-top: 10px;
    margin-bottom: 10px;
    /* text-align: center; */
    font-size: 18px;
    text-transform: uppercase;
    /* background-color: black; */
    color: aliceblue;
    height: 100%;
    padding-left: 5px;
}
.headerImgArrow{
    position: absolute;
    left: 5px;
}