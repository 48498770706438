.formWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formWrapperInput{
    width: 80%;
    margin-bottom: 10px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 1px;
    border: 1px solid #000000;
    padding: 0 16px;
    display: block;
    /* transition: box-shadow .225s,border-color .225s; */
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #222222;
}

.addAccountBtn{
    width: 80%;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 20px;
}

.addAccountBtn:active {
    transform: scale(0.95); /* Уменьшение размера при нажатии */
  }