.btnGetVillages{
    width: 80%;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.wrapperBtnGetVillages{
    text-align: center;
    /* margin-top: 50px; */
}
  .wrapperNameVillages::-webkit-scrollbar {
    width: 12px; /* Ширина полосы прокрутки */
    scrollbar-color: transparent transparent; /* Firefox */
    scrollbar-width: thin; /* Firefox */
  }

  .wrapperNameVillages::-webkit-scrollbar-thumb {
    background-color: transparent; /* Цвет полосы прокрутки */
  }
.wrapperNameVillages{
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: auto;
    top: 103px;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    padding-right: 35px;
    padding-top: 15px;
    width: 100%;
    margin-bottom: 18px;
}

.wrapperBoxResourse{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wrapperBoxCenterVillge{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.boxResourse{
  width: 33%;
  height: 100px;
  /* border: 1px solid black; */
}

.wrapperImgResourseInformation{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.wrapperImgWarehouseAndGranary{
 margin-top: 10px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
}
.wrapperImgWarehouseAndGranaryInformation{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tree{
  background-color: rgba(18,211,90,0.4);
  margin-right: 1px;
  margin-bottom: 1px;
}
.clay{
  background-color: rgba(171,132,23,0.4);
  margin-right: 1px;
  margin-bottom: 1px;
}
.iron{
  background-color: rgba(169,172,187,0.4);
  margin-right: 1px;
  margin-bottom: 1px;
}
.breed{
  background-color: rgba(244,204,59,0.6);
  margin-right: 1px;
  margin-bottom: 1px;
}
.houses{
  background-color: rgba(231,168,105,0.3);
  margin-right: 1px;
  margin-bottom: 1px;
}
.levelResourseInBox{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65%;
    font-size: 18px;
}
.levelCenterVillageBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.timerInput{
  width: 50%;
  margin-bottom: 10px;
  height: 60px;
  background-color: #FFFFFF;
  border-radius: 1px;
  border: 1px solid #000000;
  padding: 0 16px;
  display: block;
  /* transition: box-shadow .225s,border-color .225s; */
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  margin-right: 15px;
}
.startRaidTimer{
  border: 1px solid #34353c;
  box-shadow: inset 0 -4px 0 -2px #344a1d, inset 0 4px 0 -2px #b9d890, inset 0 0 0 1px #679f1b;
  background-image: linear-gradient(to bottom,#5d8137,#466428);
  height: 60px;
  width: 59%;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 20px;
}

.stopRaidTimer{
  border: 1px solid #34353c;
  box-shadow: inset 0 -4px 0 -2px #ff0018, inset 0 4px 0 -2px #ff0885, inset 0 0 0 1px #ff0023;
  background-image: linear-gradient(to bottom,#fd0101,#ff0000);
  height: 60px;
  width: 40%;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 20px;
}


