.modalChooseButton{
    width: 185px;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 1px;
    margin-bottom: 20px;
}
.wrapperModalChooseButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
}