.teachButton{
    border: 1px solid #34353c;
    box-shadow: inset 0 -4px 0 -2px #344a1d, inset 0 4px 0 -2px #b9d890, inset 0 0 0 1px #679f1b;
    background-image: linear-gradient(to bottom, #5d8137, #466428);
    height: 60px;
    width: 40%;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 20px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
}
.stopteachButton{
    border: 1px solid #34353c;
    box-shadow: inset 0 -4px 0 -2px #ff0018, inset 0 4px 0 -2px #ff0885, inset 0 0 0 1px #ff0023;
    background-image: linear-gradient(to bottom,#fd0101,#ff0000);
    height: 60px;
    width: 40%;
    color: #FFFFFF;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 20px;
}
.btnGetVillages{
    width: 80%;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}