.startBuild{
    border: 1px solid #34353c;
    box-shadow: inset 0 -4px 0 -2px #344a1d, inset 0 4px 0 -2px #b9d890, inset 0 0 0 1px #679f1b;
    background-image: linear-gradient(to bottom,#5d8137,#466428);
    height: 60px;
    /* width: 40%; */
    width: 140px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 20px;
}
.boxResourse{
padding: 20px 24px 20px 24px;
}

.wrapperNameVillages::-webkit-scrollbar {
  width: 12px; /* Ширина полосы прокрутки */
  scrollbar-color: transparent transparent; /* Firefox */
  scrollbar-width: thin; /* Firefox */
}

.wrapperNameVillages::-webkit-scrollbar-thumb {
  background-color: transparent; /* Цвет полосы прокрутки */
}
.wrapperNameVillages{
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: auto;
  flex-wrap: nowrap;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  width: 100%;

}

.wrapperInfoBoxResourse{
     width:140px;
     height:120px; 
     background-color: rgba(231,168,105,0.3);
     margin-right: 1px;
     margin-bottom: 1px; 
     margin-bottom:40px;
}

.tree{
    background-color: rgba(18,211,90,0.4);
    margin-right: 1px;
    margin-bottom: 1px;
  }
  .clay{
    background-color: rgba(171,132,23,0.4);
    margin-right: 1px;
    margin-bottom: 1px;
  }
  .iron{
    background-color: rgba(169,172,187,0.4);
    margin-right: 1px;
    margin-bottom: 1px;
  }
  .breed{
    background-color: rgba(244,204,59,0.6);
    margin-right: 1px;
    margin-bottom: 1px;
  }