.accountsimg{
    height: 70px;
    width: 50px;
    margin-right: 10px;
}
.accountsWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
}

.accountsWrapper:active{
    transform: scale(0.95);
  }

.accountsTextServer{
    margin-bottom: 5px;
}
.accountsTextStyle{
    font-size: 12px;
}
.accountsFixBottomButton{
    position: fixed;
    display: flex;
    justify-content: space-around;
    bottom: 0px;
    /* background-color:rgba(0,0,0,0.2); */
    width: 100%;
    height: 70px;
    /* border: 1px solid black; */
}
.accountsFixBottomButtonStyle{
    /* width: 50%; */ 
    padding: 10px 30px 10px 30px;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 1px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrapperWithDeleteButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}