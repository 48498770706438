.timerInput{
    width: 50%;
    margin-bottom: 10px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 1px;
    border: 1px solid #000000;
    padding: 0 16px;
    display: block;
    /* transition: box-shadow .225s,border-color .225s; */
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #222222;
    margin-right: 15px;
}

.startRaidTimer{
    border: 1px solid #34353c;
    box-shadow: inset 0 -4px 0 -2px #344a1d, inset 0 4px 0 -2px #b9d890, inset 0 0 0 1px #679f1b;
    background-image: linear-gradient(to bottom,#5d8137,#466428);
    height: 60px;
    width: 59%;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 20px;
}
.stopRaidTimer{
    border: 1px solid #34353c;
    box-shadow: inset 0 -4px 0 -2px #ff0018, inset 0 4px 0 -2px #ff0885, inset 0 0 0 1px #ff0023;
    background-image: linear-gradient(to bottom,#fd0101,#ff0000);
    height: 60px;
    width: 40%;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 10px;
}
.wrapperTimer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 60px;
    border:1px solid black;
}

.btnGetVillages{
    padding: 15px;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: "Montserrat";
}