.addAccountBtn{
    width: 50%;
    display: block;
    height: 50px;
    background-color: black;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 2px;
    margin-bottom: 20px;
    margin: 0 auto;
    margin-top: 10px;
}
.styleInput{
  width: 50%;
  margin-bottom: 10px;
  height: 60px;
  background-color: #FFFFFF;
  border-radius: 1px;
  border: 1px solid #000000;
  padding: 0 16px;
  display: block;
  /* transition: box-shadow .225s, border-color .225s; */
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  margin-right: 15px;
}
.styleInputBtn{
  width: 100%;
  margin-bottom: 10px;
  height: 60px;
  background-color: #FFFFFF;
  border-radius: 1px;
  border: 1px solid #000000;
  padding: 0 16px;
  display: block;
  /* transition: box-shadow .225s, border-color .225s; */
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  margin-right: 15px;
}
.addAccountBtn:active {
    transform: scale(0.95); /* Уменьшение размера при нажатии */
  }

.styleInputTroops{
  margin-bottom: 10px;
  height: 60px;
  background-color: #FFFFFF;
  border-radius: 1px;
  border: 1px solid #000000;
  padding: 0 16px;
  /* transition: box-shadow .225s, border-color .225s; */
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  margin-right: 15px; 
}  